<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 12L4 18V6L12.5 12ZM21.5 12L13 18V6L21.5 12Z"
      fill="#ffffff"
    />
  </svg>
</template>

<script>
export default {
  name: 'Entry',
}
</script>
